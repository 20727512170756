import { type ActionFunctionArgs, json, LoaderFunctionArgs, redirect } from '@remix-run/node'; // or cloudflare/deno // or cloudflare/deno
import { Form, useActionData, useNavigate } from '@remix-run/react';
import { useEffect } from 'react';
import { API_HOST } from '@common/constants';
import { getUserToken } from '@server/login/login.server';
import { useSessionStore } from '@store/provider/session.store.provider';

type ReturnType = {
    errors?: string;
    status?: number;
    data?: {
        cesCode: string;
    };
};

export async function loader({ request }: LoaderFunctionArgs) {
    const codeinfo = await getUserToken(request);

    if (codeinfo) {
        return redirect('/ces');
    }

    return json({});
}

export const action = async ({ request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const returnType: ReturnType = {
        errors: '',
        data: {
            cesCode: '',
        },
    };
    const code = formData.get('code');

    if (!code) {
        returnType.errors = 'Password field is required';
        return json({ ...returnType });
    } else {
        if (code === 'MELqa5ODtqWthJC') {
            returnType.status = 200;
            returnType.data = {
                cesCode: 'MELqa5ODtqWthJC',
            };
        } else {
            returnType.status = 404;
            returnType.errors = 'Password is incorrect';
        }
    }

    /*
    const res = await fetch(`${API_HOST}/api/v1/ces/authenticate/code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cesCode: code }),
    });
    const data = await res.json();
    console.log(data);

    if (data.status === 404) {
        returnType.status = 404;
        returnType.errors = '일치하는 [코드]가 없습니다.';
    } else {
        returnType.status = 200;
        returnType.data = {
            cesCode: data.content.cesCode,
        };
    }
     */

    return json({ ...returnType });
};

export default function Index() {
    const navigate = useNavigate();
    const { code, setCode } = useSessionStore((store) => store);
    const actionData = useActionData<typeof action>() as ReturnType;

    useEffect(() => {
        if (actionData && actionData.status === 200) {
            setCode(actionData.data.cesCode);
        }
    }, [actionData]);

    useEffect(() => {
        if (code) {
            navigate('/ces', {
                replace: true,
            });
        }
    }, [code]);

    return (
        <main className="mc">
            <Form method="post">
                <div className="mc-f">
                    <input type="text" name="code" className="mc-t" placeholder="Password" />
                    <button className="mc-b" style={{ width: '200px' }}>
                        Enter Password
                    </button>
                </div>
            </Form>
            {actionData?.errors ? <p className="error on">{actionData?.errors}</p> : null}
        </main>
    );
}
